import React from "react"
import { StaticQuery, graphql } from "gatsby"
import ShopCard from "./shopcard"

const ShopItemsPremade = ({ data }) => {
  return (
    <StaticQuery
      query={graphql`
        query premadequery {
          allDatoCmsPremadeBox {
            edges {
              node {
                id
                name
                description
                descriptionShort
                price
                slug
                photos {
                  alt
                  originalId
                  fluid {
                    ...GatsbyDatoCmsFluid
                  }
                }
                supplementsInTheBox {
                  name
                  slug
                }
              }
            }
          }
        }
      `}
      render={data => {
        const premadeData = data.allDatoCmsPremadeBox.edges
        return (
          <>
            <h1 id="recommended" className="shop__header">
              Recommended Boxes
            </h1>
            <p className="shop__subtitle">
              Discover the best anxiety relief supplement for your body with our
              2, 4 and 6 week recommendations
            </p>

            <div className="u-margin-top-medium shop__container">
              {premadeData.map(({ node: premadeBox }) => {
                return (
                  <ShopCard
                    key={premadeBox.id}
                    id={premadeBox.id}
                    photos={premadeBox.photos}
                    name={premadeBox.name}
                    price={premadeBox.price}
                    description={premadeBox.descriptionShort}
                    link={`/shop/box/${premadeBox.slug}`}
                  />
                )
              })}
            </div>
          </>
        )
      }}
    />
  )
}

export default ShopItemsPremade
