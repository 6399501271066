import React from "react"


const SnipcartButtonShopPage = props => {
  return (
    <button
    className="snipcart-add-item button__addtocart"
    data-item-id={props.id}
    data-item-price={props.price}
    data-item-url={`https://galinihealth.com${props.link}`}
    data-item-description={props.description}
    data-item-image={props.imagesrc}
    data-item-name={props.name}
  >
    {props.buttonText}
  </button>
  )
}

export default SnipcartButtonShopPage