import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

//STYLING IN LEARN SCSS

const LearnNavigate = (props) => {
  const {
    allDatoCmsSupplement: { edges: supplementData },
  } = useStaticQuery(graphql`
    query {
      allDatoCmsSupplement {
        edges {
          node {
            id
            slug
            title
            boxImage {
              fluid {
                ...GatsbyDatoCmsFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className="learnNav">
      {supplementData.map(({ node: supplement }) => {

        return (

            <div key={supplement.id} className={props.currentSupplement === supplement.title ? "u-margin-top-small learnNav__image learnNav__image__currPage" : "u-margin-top-small learnNav__image"}>
                <Link activeClassName="learnNav__activeLink" className="link learnNav__link" to={`/learn/${supplement.slug}`}>
                    <Img fluid={supplement.boxImage.fluid} alt={supplement.title} />
                    <span className="learnNav__title">{supplement.title}</span>
                </Link>
            </div>

        )
      })}
    </div>
  )
}

export default LearnNavigate
