import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"


import SnipcartButtonShopPage from "../buttons/snipcartbuttonshoppage"

import randomNumber from '../../functions/randomNumber'

const ShopCard = props => {

  const randomPhotoForHover = randomNumber(1, (props.photos.length - 1));

  return (
    <div key={props.id} className="shop__product">
      <Link className="shop__product__link" to={props.link}>

      <div className="shop__product__img__container">
      <div className="shop__product__img__nohover">

        <Img fluid={props.photos[0].fluid} alt={props.photos[0].alt} />
      </div>
      <div className="shop__product__img__hover">

        <Img fluid={props.photos[randomPhotoForHover].fluid} alt={props.photos[1].alt} />
      </div>
      <div className="shop__product__img__buyButton">
        <SnipcartButtonShopPage
            key={props.id}
            id={props.id}
            price={props.price}
            link={props.link}
            description={props.description}
            imagesrc={props.photos[0].fluid.src}
            name={props.name}
            buttonText="+ Quick Add"
          />
      </div>
      </div>


        <div className="shop__product__header">
          <div className="u-margin-top-small shop__product__header__name">
            {props.name}
          </div>
          <p className="shop__product__header__price">${props.price}</p>
        </div>
        <p className="u-margin-top-small shop__product__shortDescrip">
          {props.description}
        </p>
      </Link>




    </div>
  )
}

export default ShopCard
