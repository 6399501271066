import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/buttons/button"

import LearnNavigate from "../components/learn/learnNavigate"
import OurProducts from "../components/learn/ourproducts"
import ResearchCard from "../components/learn/researchcard"

import OurSupplements from "../components/MainPage/oursupplements"

export const query = graphql`
  query($slug: String!) {
    datoCmsSupplement(slug: { eq: $slug }) {
      id
      benefits
      benefitsLong
      description
      title
      boxImage {
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
      researchImage {
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
      titleImage {
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
      descriptionLong
      researchLink {
        description
        id
        supplement
        moreArticlesLink
        title1
        title2
        title3
        title4
        findings1
        findings2
        findings3
        findings4
        link1
        link2
        link3
        link4
      }
    }
  }
`

const Learn = ({ data }) => {
  const supplement = data.datoCmsSupplement

  return (
    <Layout>
      <SEO title="Learn" />
      <section className="u-standard-section-margins learn">
        <div className="learn__left">
          <Img fluid={supplement.titleImage.fluid} alt={supplement.title} />

        </div>

        <div className="learn__right">
          <h1 className="u-margin-bottom-small learn__title">
            {supplement.title}
          </h1>

          <h2 className="u-margin-bottom-small learn__subtitle">Benefits</h2>
          <p className="u-margin-bottom-small">{supplement.benefitsLong}</p>

          <h2 className="u-margin-bottom-small learn__subtitle">
            About this powerful herb
          </h2>
          <p className="u-margin-bottom-medium">{supplement.descriptionLong}</p>

          <Button type="primary" text={`Shop ${supplement.title} Products`} link="shop" />


        </div>
      </section>
        <div className="u-standard-section-margins learn__moresupps">
          <h1>Explore more supplements:</h1>
            <LearnNavigate currentSupplement={supplement.title} />

          </div>

      <section className="u-standard-section-margins learn__research">
        <div className="learn__research__left">
          <Img fluid={supplement.researchImage.fluid} alt={supplement.title} />
        </div>

        <div className="learn__research__right">
          <h1 className="u-margin-bottom-small learn__title">
            Research-Backed
          </h1>
          <p className="u-margin-bottom-small">{supplement.researchLink.description}</p>
          <h2 className="u-margin-bottom-small learn__subtitle">
            Feeling nerdy? Dive into some of the science!
          </h2>
          <div className="researchcard__grid">
            <ResearchCard 
                title={supplement.researchLink.title1}
                findings={supplement.researchLink.findings1}
                link={supplement.researchLink.link1}
            />
            <ResearchCard 
                title={supplement.researchLink.title2}
                findings={supplement.researchLink.findings2}
                link={supplement.researchLink.link2}
            />
            <ResearchCard 
                title={supplement.researchLink.title3}
                findings={supplement.researchLink.findings3}
                link={supplement.researchLink.link3}
            />
            <ResearchCard 
                title={supplement.researchLink.title4} 
                findings={supplement.researchLink.findings4}
                link={supplement.researchLink.link4}
            />

          </div>
          <div className="u-margin-top-small researchcard__morearticles">
          <a href={supplement.researchLink.moreArticlesLink}>
          <p>
            See more research articles

          </p>
          </a>

          </div>
        </div>
      </section>
      <OurProducts />
      <OurSupplements />
    </Layout>
  )
}

export default Learn
