import React from "react"

import Button from "../buttons/button"

import ShopItemsPremade from "../shop/shopitemspremade"

const OurProducts = () => {
    return(
        <section className="u-standard-section-margins ourproducts">
            <ShopItemsPremade />
            <div className="u-margin-top-medium">
                <Button
                    type="primary"
                    text="Shop All Products"
                    link="shop"
                />
            </div>
        </section>
    )
}

export default OurProducts;