import React from "react"

const ResearchCard = props => {
  return (
    <a className="researchcard__link" href={props.link} target="_blank" rel="noopener noreferrer">
      <div className="researchcard__card">
        <p className="researchcard__title">{props.title}</p>
        <p className="u-margin-top-small researchcard__findings">
          <span>Findings: &nbsp;</span>
          {props.findings}
        </p>
      </div>
    </a>
  )
}

export default ResearchCard
